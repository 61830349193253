import { clientFolderShareableLink } from '@features/routing'
import type { SDKMessage, SDKResponse } from '@features/sdk-module'
import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'
import { stOrganizationModule } from '@features/st-organizations/st-organization-module'
import { PlatformMessage } from '@features/st-pdf-viewer/platform-module'
import { defineModule } from '@st/redux'
import { Entities, Folder, FolderSummary, FolderTag, Organization } from '@st/sdk'
import { RETURN_TYPES } from '@st/tax-folder'
import { setToggle } from '@st/util/array-set'
import { isNotEmpty } from '@st/util/json-value'
import { search } from '@st/util/search'
import {
  asc,
  direction,
  levels,
  rank,
  sort,
  SortDirection,
  SortState,
  SortTransitions,
  toggleSort
} from '@st/util/sort'

type STFolderListState = {
  status: 'loading' | 'loaded'
  organizationId: string

  items: Folder[]
  organization: Organization | undefined
  filters: STFolderListFilters
  tags: FolderTag[]

  selectedFolderIds: string[]

  sortState: SortState<FolderListSortColumn> | undefined
}

export type STFolderAction =
  | { type: 'openSendQuestionnaireDialog'; folder: FolderRow }
  | { type: 'openFolder'; folder: FolderRow }
  | { type: 'deleteFolder'; folder: FolderRow }
  | { type: 'editFolderTags'; folder: FolderRow }
  | { type: 'viewRemindersSchedule'; folder: FolderRow }
  | { type: 'sendQuestionnaires'; folderIds: string[] }
  | { type: 'enrollInReminders'; folderIds: string[] }
  | { type: 'deleteFolders'; folderIds: string[] }
  | { type: 'copyQuestionnaireLink'; folderId: string }
  | { type: 'previewQuestionnaire'; folderId: string }
  | { type: 'downloadQuestionnaireLinks'; folderIds: string[] }

export type FolderRow = Pick<
  FolderSummary,
  | 'id'
  | 'entities'
  | 'type'
  | 'year'
  | 'questionnaireStatus'
  | 'questionnaireSent'
  | 'questionnaireEnrolledInReminders'
  | 'tagIds'
  | 'checklistItemsCompleteCount'
  | 'checklistItemsTotalCount'
  | 'questionnaireUpdatedAt'
  | 'createdAt'
>

export type QuestionnareFilterOptionKey =
  | 'not_seen_yet'
  | 'not_seen_yet_not_sent'
  | 'not_seen_yet_sent'
  | 'in_progress'
  | 'submitted'

type QuestionnaireFilterOption = {
  label: string
  value: QuestionnareFilterOptionKey | undefined
}

export const QUESTIONNAIRE_STATUS_FILTER_OPTIONS: QuestionnaireFilterOption[] = [
  { label: 'Not seen yet', value: 'not_seen_yet' },
  { label: 'Not seen yet, Not sent', value: 'not_seen_yet_not_sent' },
  { label: 'Not seen yet, Sent', value: 'not_seen_yet_sent' },
  { label: 'In progress', value: 'in_progress' },
  { label: 'Submitted', value: 'submitted' }
]

export type STFolderListFilters = {
  keywords: string
  year?: number
  questionnaireStatus?: QuestionnareFilterOptionKey
  type?: string
}

export type STFolderListMessage =
  | { type: 'load' }
  | { type: 'updateFilters'; filters: STFolderListFilters }
  | { type: 'copyQuestionnaireLink'; folderId: string }
  | { type: 'folderListUpdated'; folderId: string }
  | { type: 'toggleFolderSelected'; folderId: string }
  | { type: 'deselectAll' }
  | { type: 'toggleSelectAll' }
  | { type: 'toggleSort'; column: FolderListSortColumn }
  | SDKResponse

type STFolderListSend = {
  sdk: SDKMessage
  platform: PlatformMessage
}

type STFolderListInit = {
  organizationId: string
}

type STFolderListDeps = {
  stOrganization: typeof stOrganizationModule
}

export const stFolderListModule = defineModule<
  STFolderListState,
  STFolderListMessage,
  STFolderListInit,
  STFolderListSend,
  STFolderListDeps
>({
  name: 'stFolderList',
  init: ({ organizationId }) => {
    return [
      {
        status: 'loading',
        organizationId: organizationId,
        items: [],
        filters: { keywords: '' },
        organization: undefined,
        tags: [],
        selectedFolderIds: [],
        sortState: FOLDER_DEFAULT_SORT
      } satisfies STFolderListState,
      {
        sdk: {
          type: 'request',
          request: { type: 'folders/getFolderListState', organizationId: organizationId }
        }
      }
    ]
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'load':
        return [
          state,
          {
            sdk: {
              type: 'request',
              request: { type: 'folders/getFolderListState', organizationId: state.organizationId }
            }
          }
        ]
      case 'updateFilters':
        return { ...state, filters: message.filters }
      case 'copyQuestionnaireLink':
        const link = clientFolderShareableLink({
          folderId: message.folderId,
          organizationSlug: state.organization!.slug
        })
        return [
          state,
          {
            platform: [
              { type: 'copyToClipboard', text: link },
              { type: 'showSnackbar', message: 'Copied questionnaire link' }
            ]
          }
        ]
      case 'response':
        const op = message.operation
        if (op.type == 'folders/getFolderListState') {
          return {
            ...state,
            status: 'loaded',
            items: op.response.items,
            organization: op.response.organization,
            tags: op.response.tags,
            // Deselect folders that are no longer in the list
            selectedFolderIds: state.selectedFolderIds.filter((id) =>
              op.response.items.some((f) => f.id == id)
            )
          }
        }
      case 'folderListUpdated':
        return [
          state,
          {
            sdk: {
              type: 'request',
              request: { type: 'folders/getFolderListState', organizationId: state.organizationId }
            }
          }
        ]
      case 'toggleFolderSelected':
        return {
          ...state,
          selectedFolderIds: setToggle(state.selectedFolderIds, message.folderId)
        }
      case 'toggleSelectAll':
        var allFoldersSelected = selAllFoldersSelected(state)
        return {
          ...state,
          selectedFolderIds: allFoldersSelected ? [] : selFinalFolderList(state).map((f) => f.id)
        }
      case 'deselectAll':
        return { ...state, selectedFolderIds: [] }
      case 'toggleSort':
        return {
          ...state,
          sortState:
            toggleSort(state.sortState, message.column, FOLDER_SORT_TRANSITIONS) ??
            FOLDER_DEFAULT_SORT
        }
      default:
        return state
    }
  }
})

export function selAllFoldersSelected(state: STFolderListState): boolean {
  const selectedIds = new Set(state.selectedFolderIds)
  return filterFolderList(state.items, state.filters, state.tags).every((f) =>
    selectedIds.has(f.id)
  )
}

export function selFinalFolderList(state: STFolderListState): FolderRow[] {
  const filteredFolders = filterFolderList(state.items, state.filters, state.tags)
  return sortFolderList(filteredFolders, state.sortState)
}

function folderSummarySearchIndex(item: Folder, tags: FolderTag[]): string {
  const els = [formatFolderEntityName(item.entities)]
  for (const tid of item.tagIds) {
    const tag = tags.find((t) => t.id == tid)
    if (tag?.label) {
      els.push(tag?.label)
    }
  }
  return els.join(' ')
}

function filterFolderList(
  items: Folder[],
  filter: STFolderListFilters,
  tags: FolderTag[]
): FolderRow[] {
  var filteredItems: Folder[] = items

  if (isNotEmpty(filter.keywords)) {
    filteredItems = search(
      filteredItems,
      (el) => {
        const searchIndex = folderSummarySearchIndex(el, tags)
        return searchIndex
      },
      filter.keywords
    )
  }

  if (filter.year) {
    filteredItems = filteredItems.filter((el) => el.year == filter.year)
  }
  if (filter.type) {
    filteredItems = filteredItems.filter((el) => el.type == filter.type)
  }

  if (filter.questionnaireStatus) {
    filteredItems = filteredItems.filter((el) =>
      filterByQuestionnaireStatus(el, filter.questionnaireStatus!)
    )
  }

  return filteredItems
}

function sortFolderList(
  items: FolderRow[],
  sortState: SortState<FolderListSortColumn> | undefined
): FolderRow[] {
  sortState = sortState ?? { column: 'entities', direction: 'asc' }

  switch (sortState.column) {
    case 'entities':
      return sort(
        items,
        levels(
          (a, b) => compareEntities(sortState.direction, a.entities, b.entities),
          direction(sortState.direction, 'type')
        )
      )
    case 'createdAt':
      return sort(items, levels(direction(sortState.direction, 'createdAt')))
    case 'questionnaireUpdatedAt':
      return sort(
        items,
        levels(
          direction(sortState.direction, 'questionnaireUpdatedAt'),
          direction(sortState.direction, 'createdAt')
        )
      )
    case 'year':
      return sort(items, levels(direction(sortState.direction, 'year')))
    case 'type':
      return sort(items, rank('type', RETURN_TYPES, sortState.direction))
    case 'questionnaire':
      return sort(
        items,
        levels(
          rank(
            'questionnaireStatus',
            ['not_seen_yet', 'in_progress', 'submitted'],
            sortState.direction
          )
        )
      )
    case 'checklist':
      return sort(
        items,
        levels(
          direction(sortState.direction, (s) => {
            if (s.checklistItemsTotalCount == 0) return 0
            return s.checklistItemsCompleteCount / s.checklistItemsTotalCount
          }),
          direction(sortState.direction, 'checklistItemsTotalCount')
        )
      )
  }
}

function compareEntities(direction: SortDirection, a: Entities, b: Entities): number {
  const value = functionGetCompareName(a).localeCompare(functionGetCompareName(b), 'en', {
    sensitivity: 'base'
  })
  return direction == 'asc' ? value : -value
}

function functionGetCompareName(entities: Entities): string {
  const name = formatFolderEntityName(entities)
  // push empty names to the bottom of the list
  return name == '' ? 'zzzzz' : name
}

function filterByQuestionnaireStatus(
  item: FolderSummary,
  status: QuestionnareFilterOptionKey
): boolean {
  switch (status) {
    case 'not_seen_yet':
      return item.questionnaireStatus == 'not_seen_yet'
    case 'not_seen_yet_not_sent':
      return item.questionnaireStatus == 'not_seen_yet' && !item.questionnaireSent
    case 'not_seen_yet_sent':
      return item.questionnaireStatus == 'not_seen_yet' && item.questionnaireSent
    case 'in_progress':
      return item.questionnaireStatus == 'in_progress'
    case 'submitted':
      return item.questionnaireStatus == 'submitted'
    default:
      return true
  }
}

export function selFoldersById(state: STFolderListState, folderIds: string[]): Folder[] {
  const folderIdsSet = new Set(folderIds)
  return state.items.filter((el) => folderIdsSet.has(el.id))
}

export type FolderListSortColumn =
  | 'entities'
  | 'createdAt'
  | 'questionnaireUpdatedAt'
  | 'year'
  | 'questionnaire'
  | 'type'
  | 'checklist'

export const FOLDER_DEFAULT_SORT: SortState<FolderListSortColumn> = {
  column: 'createdAt',
  direction: 'desc'
}

export const FOLDER_SORT_TRANSITIONS: SortTransitions<FolderListSortColumn> = {
  entities: ['asc', 'desc'],
  createdAt: ['asc', 'desc'],
  questionnaireUpdatedAt: ['asc', 'desc'],
  year: ['desc', 'asc'],
  questionnaire: ['desc', 'asc'],
  type: ['asc', 'desc'],
  checklist: ['desc', 'asc']
}
