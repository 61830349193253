import { assetURL } from '@features/app-hooks'
import { TaxAppId } from '@st/tax-folder'
import { DocumentDropZone, DropHere, DropProgress, ProgressStepIndicator, Wizard } from '@st/theme'
import { Button } from '@st/theme'
import { ReactNode, useState } from 'react'
import { match } from 'ts-pattern'

type UploadProgress = {
  bytesTransferred: number
  totalBytes: number
}

type Props = {
  taxAppId: TaxAppId
  onClose: () => void
  onBack: () => void
  onDrop: (dataTransfer: DataTransfer) => void
  uploadProgress?: UploadProgress
}

export function UploadFolderImportWizard({
  taxAppId,
  onClose,
  onBack,
  onDrop,
  uploadProgress
}: Props) {
  switch (taxAppId) {
    case 'axcess':
      return (
        <AxcessImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadProgress={uploadProgress}
        />
      )
    case 'drake':
      return (
        <DrakeImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadProgress={uploadProgress}
        />
      )
    case 'lacerte':
      return (
        <LacerteImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadProgress={uploadProgress}
        />
      )
    case 'proseries':
      return (
        <ProseriesImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadProgress={uploadProgress}
        />
      )
    case 'prosystem':
      return (
        <ProsystemImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadProgress={uploadProgress}
        />
      )
    case 'ultratax':
      return (
        <UltrataxImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadProgress={uploadProgress}
        />
      )
    case 'proconnect':
      return (
        <ProconnectImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadProgress={uploadProgress}
        />
      )
    case 'csv':
      return (
        <CSVImportInstructions
          onClose={onClose}
          onBack={onBack}
          onDrop={onDrop}
          uploadProgress={uploadProgress}
        />
      )
    default:
      return <div>Not implemented</div>
  }
}

function VisualStep({
  title,
  subtitle,
  image,
  stepNumber,
  stepCount
}: {
  title?: string
  subtitle?: string
  image?: string
  caption?: string
  stepNumber: number
  stepCount: number
}) {
  return (
    <Wizard.Main
      variant="wide"
      progress={
        stepCount > 1 && <ProgressStepIndicator stepNumber={stepNumber} stepCount={stepCount} />
      }
      title={title}
      subtitle={subtitle}
    >
      {image && (
        <img className="w-[1000px] object-contain" src={assetURL(`instructions/${image}`)} />
      )}
    </Wizard.Main>
  )
}

function UploadStep({
  title,
  subtitle,
  uploadTitle,
  uploadSubtitle,
  stepNumber,
  stepCount,
  onDrop,
  uploadProgress,
  children
}: {
  title?: string
  subtitle?: string
  uploadTitle: ReactNode
  uploadSubtitle: string
  stepNumber: number
  stepCount: number
  onDrop: (dataTransfer: DataTransfer) => void
  uploadProgress?: UploadProgress
  children?: ReactNode
}) {
  return (
    <Wizard.Main
      variant="wide"
      progress={
        stepCount > 1 && <ProgressStepIndicator stepNumber={stepNumber} stepCount={stepCount} />
      }
      title={title}
      subtitle={subtitle}
    >
      {children}

      <DocumentDropZone className="w-[600px]" onDrop={onDrop}>
        {uploadProgress ? (
          <DropProgress
            progress={uploadProgress.bytesTransferred / uploadProgress.totalBytes}
            caption="Uploading..."
          />
        ) : (
          <DropHere caption={uploadSubtitle}>{uploadTitle}</DropHere>
        )}
      </DocumentDropZone>
    </Wizard.Main>
  )
}

// Axcess
function AxcessImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadProgress
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 6

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={6}
            title="Add clients from Axcess"
            image="axcess/2024/axcess-instructions-1.png"
            subtitle="Open Axcess 2023 (the prior year's tax software) and select the clients you'd like to add to StanfordTax."
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={6}
            title="Add clients from Axcess"
            image="axcess/2024/axcess-instructions-2.png"
            subtitle='Click "Utilities" and select "Transfer to ProSystem fx." You do not need to have ProSystem fx; this feature just creates an export file. If the button is disabled, you may need the "Return transfer" permission.'
          />
        ))
        .with(3, () => (
          <VisualStep
            stepNumber={3}
            stepCount={6}
            title="Add clients from Axcess"
            image="axcess/2024/axcess-instructions-3.png"
            subtitle='Enter an "Office group name," select an "Export file location," and then click "Transfer." We recommend creating a folder on your Desktop named "StanfordTax" and exporting to that folder.'
          />
        ))
        .with(4, () => (
          <VisualStep
            stepNumber={4}
            stepCount={6}
            title="Add clients from Axcess"
            image="axcess/2024/axcess-instructions-4.png"
            subtitle="Wait for the process to complete. Axcess is preparing an export file at this step, not actually transferring returns to ProSystem fx."
          />
        ))
        .with(5, () => (
          <VisualStep
            stepNumber={5}
            stepCount={6}
            title="Add clients from Axcess"
            image="axcess/2024/axcess-instructions-5.png"
            subtitle="When the process completes, note the name of the export file (all clients are included in a single .dat file). This is the file you will upload to StanfordTax."
          />
        ))
        .with(6, () => (
          <UploadStep
            stepNumber={6}
            stepCount={6}
            title="Add clients from Axcess"
            subtitle="Locate the exported file on your computer and upload it below."
            uploadTitle={
              <>
                Drop your backup here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadProgress={uploadProgress}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Drake
function DrakeImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadProgress
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 5

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={5}
            title="Add clients from Drake"
            image="drake/drake-instructions-1.png"
            subtitle="Open Drake 2023 and go to Tools > File Maintenance > Backup."
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={5}
            title="Add clients from Drake"
            image="drake/drake-instructions-2.png"
            subtitle='Select where to store the backup, choose "Selective Backup," and click "Backup."'
          />
        ))
        .with(3, () => (
          <VisualStep
            stepNumber={3}
            stepCount={5}
            title="Add clients from Drake"
            image="drake/drake-instructions-3.png"
            subtitle='Select "Customize Backup" and then "Tax Returns." Choose the clients you want and click "Backup."'
          />
        ))
        .with(4, () => (
          <VisualStep
            stepNumber={4}
            stepCount={5}
            title="Add clients from Drake"
            image="drake/drake-instructions-4.png"
            subtitle="Drake will generate the backup file."
          />
        ))
        .with(5, () => (
          <UploadStep
            stepNumber={5}
            stepCount={5}
            title="Add clients from Drake"
            subtitle="Locate the backup file (e.g., something like D22_DB_05-26-23_15-51.7z) on your computer and upload it below."
            uploadTitle={
              <>
                Drop your backup here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadProgress={uploadProgress}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Lacerte
function LacerteImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadProgress
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 3

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={3}
            title="Add clients from Lacerte"
            image="lacerte/lacerte-instructions-1.png"
            subtitle='Open Lacerte 2023, select the clients you want, and then select "Backup" from the Client menu.'
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={3}
            title="Add clients from Lacerte"
            image="lacerte/lacerte-instructions-2.png"
            subtitle='Select where to store the backup and click "OK."'
          />
        ))
        .with(3, () => (
          <UploadStep
            stepNumber={3}
            stepCount={3}
            title="Add clients from Lacerte"
            subtitle="Drag and drop the entire DETAIL folder below."
            uploadTitle={
              <>
                Drop your folder here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadProgress={uploadProgress}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Proseries
function ProseriesImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadProgress
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 4

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={4}
            title="Add clients from ProSeries"
            image="proseries/proseries-instructions-1.png"
            subtitle='Open ProSeries 2023 and go to "HomeBase." Select the clients you want to add to StanfordTax.'
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={4}
            title="Add clients from ProSeries"
            image="proseries/proseries-instructions-2.png"
            subtitle="Go to File > Client File Maintenance > Copy/Backup."
          />
        ))
        .with(3, () => (
          <VisualStep
            stepNumber={3}
            stepCount={4}
            title="Add clients from ProSeries"
            image="proseries/proseries-instructions-3.png"
            subtitle='Select where to store the backup and click "Backup Clients."'
          />
        ))
        .with(4, () => (
          <UploadStep
            stepNumber={4}
            stepCount={4}
            title="Add clients from ProSeries"
            subtitle="Locate the folder where the backup was saved (it will contain .23i and .23i.cache files). Drag and drop the entire folder below."
            uploadTitle={
              <>
                Drop your folder here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadProgress={uploadProgress}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Prosystem
function ProsystemImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadProgress
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 8

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-1.png"
            subtitle='In Office Manager, select "Backup Client Data."'
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-2.png"
            subtitle='Select "All Clients" and click "OK."'
          />
        ))
        .with(3, () => (
          <VisualStep
            stepNumber={3}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-3.png"
            subtitle='Select the clients you want to add, then click "Add."'
          />
        ))
        .with(4, () => (
          <VisualStep
            stepNumber={4}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-4.png"
            subtitle='Click "Select Drive."'
          />
        ))
        .with(5, () => (
          <VisualStep
            stepNumber={5}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-5.png"
            subtitle='Choose where to store the backup file, then click "OK."'
          />
        ))
        .with(6, () => (
          <VisualStep
            stepNumber={6}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-6.png"
            subtitle='If prompted to overwrite, select "Overwrite All Files."'
          />
        ))
        .with(7, () => (
          <VisualStep
            stepNumber={7}
            stepCount={8}
            title="Add clients from ProSystem fx"
            image="prosystem/prosystem-instructions-7.png"
            subtitle='Assign a name for the PDF report and click "Save."'
          />
        ))
        .with(8, () => (
          <UploadStep
            stepNumber={8}
            stepCount={8}
            title="Add clients from ProSystem fx"
            subtitle="Locate the folder with the .ZIP files and CLNTBKUP.001. Drag and drop the entire folder below."
            uploadTitle={
              <>
                Drop your folder here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadProgress={uploadProgress}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Ultratax
function UltrataxImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadProgress
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 7

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-1.png"
            subtitle='Open UltraTax 2023 and select "Backup" in the File menu.'
          />
        ))
        .with(2, () => (
          <VisualStep
            stepNumber={2}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-2.png"
            subtitle='Select the clients you want and click "Select."'
          />
        ))
        .with(3, () => (
          <VisualStep
            stepNumber={3}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-3.png"
            subtitle="Select where you'd like the backup to be stored."
          />
        ))
        .with(4, () => (
          <VisualStep
            stepNumber={4}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-4.png"
            subtitle='Uncheck the "Encrypt client backup" checkbox.'
          />
        ))
        .with(5, () => (
          <VisualStep
            stepNumber={5}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-5.png"
            subtitle='Click "Backup."'
          />
        ))
        .with(6, () => (
          <VisualStep
            stepNumber={6}
            stepCount={7}
            title="Add clients from UltraTax"
            image="ultratax/ultratax-instructions-6.png"
            subtitle='Click "Done."'
          />
        ))
        .with(7, () => (
          <UploadStep
            stepNumber={7}
            stepCount={7}
            title="Add clients from UltraTax"
            subtitle="Locate the folder containing CltBKLst.WT3 and the client folders. Drag and drop the entire folder below."
            uploadTitle={
              <>
                Drop your folder here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadProgress={uploadProgress}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

// Proconnect
function ProconnectImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadProgress
}: Omit<Props, 'taxAppId'>) {
  const [stepNumber, setStepNumber] = useState(1)
  const stepCount = 2

  function onNext() {
    setStepNumber(stepNumber + 1)
  }

  function onPrev() {
    if (stepNumber === 1) {
      onBack()
    } else {
      setStepNumber(stepNumber - 1)
    }
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
          <Button size="l" variant="primary" disabled={stepNumber === stepCount} onClick={onNext}>
            Next
          </Button>
        </Wizard.BottomNav>
      }
    >
      {match(stepNumber)
        .with(1, () => (
          <VisualStep
            stepNumber={1}
            stepCount={2}
            title="Add clients from ProConnect"
            image="proconnect/pro-connect-call-icon.png"
            subtitle='Contact ProConnect support to request a "Lacerte Data File." They typically deliver it within about one week, even though they may say it takes longer.'
          />
        ))
        .with(2, () => (
          <UploadStep
            stepNumber={2}
            stepCount={2}
            title="Add clients from ProConnect"
            subtitle="Once you have the Lacerte Data File from ProConnect support, upload it below."
            uploadTitle={
              <>
                Drop your file here <a className="text-blue-500">or browse</a>
              </>
            }
            uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
            uploadProgress={uploadProgress}
            onDrop={onDrop}
          />
        ))
        .run()}
    </Wizard.Scaffold>
  )
}

function CSVImportInstructions({
  onClose,
  onBack,
  onDrop,
  uploadProgress
}: Omit<Props, 'taxAppId'>) {
  function onPrev() {
    onBack()
  }

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button size="l" variant="default" onClick={onPrev}>
            Back
          </Button>
        </Wizard.BottomNav>
      }
    >
      <UploadStep
        stepNumber={1}
        stepCount={1}
        title="Add clients from CSV"
        subtitle="Download the sample CSV templates and fill them out with your clients' information. Then upload them below."
        uploadTitle={
          <>
            Drop your .csv files here <a className="text-blue-500">or browse</a>
          </>
        }
        uploadSubtitle="1040, 1120, 1120S, and 1065 supported"
        uploadProgress={uploadProgress}
        onDrop={onDrop}
      >
        <div className="mb-4 flex flex-row gap-2">
          <Button variant="default" href="/samples/stanfordtax-sample-individual.csv">
            Download Individual CSV Template
          </Button>
          <Button variant="default" href="/samples/stanfordtax-sample-corporate.csv">
            Download Corporate CSV Template
          </Button>
        </div>
      </UploadStep>
    </Wizard.Scaffold>
  )
}
