import { Link } from '@features/link'

import { ErrorMessage } from '@components/error-message'
import { Option } from '@domain/entities'
import { envVar } from '@env'
import { useSDK } from '@features/app-deps-provider'
import { useAuthClient, useAuthState, useSetRoute } from '@features/app-hooks'
import { formatAuthError } from '@features/auth'
import { AuthError } from '@features/auth/auth-client-adapter'
import { authModule, loginWithEmailPassword } from '@features/auth/auth-module'
import { routeModule } from '@features/route-module'
import { RadioSelect } from '@features/ui-components'
import { useProcess, useProcessState } from '@st/redux'
import { Organization, OrganizationMembership } from '@st/sdk'
import { BrandLogo, Button, FullPageCard, TextInput } from '@st/theme'
import { getQueryParams, removeQueryParams } from '@st/util/url'
import { usePromise } from '@util/promise'
import { ReactNode, useEffect, useState } from 'react'
import { P, match } from 'ts-pattern'
import { OrganizationLogo } from '../components/organization-logo'

type Props = {
  header: ReactNode
}

/**
 * The login screen that an accountant is redirected to if they want to login to the organization app.
 */
export function OrganizationLoginPage({ header }: Props) {
  const { status } = useAuthState()

  return (
    <FullPageCard>
      {match(status)
        .with('loggedIn', () => <LoggedInNavigator />)
        .with(P.union('unknown', 'loggedOut', 'loggingIn', 'signingUp', 'error'), () => (
          <LoginForm header={header} />
        ))
        .exhaustive()}
    </FullPageCard>
  )
}

function LoginForm({ header }: { header: ReactNode }) {
  const route = useProcess(routeModule)

  const resetPasswordToken = useProcessState(routeModule, (s) => {
    const searchParams = getQueryParams(s.location.pathname)
    return searchParams['reset-password-token'] ?? undefined
  })

  if (resetPasswordToken) {
    return (
      <ResetPasswordForm
        header={header}
        token={resetPasswordToken}
        onClickLogin={() => {
          const { location } = route.getState()
          route.send({
            type: 'navigate',
            pathname: removeQueryParams(location.pathname, ['reset-password-token'])
          })
        }}
      />
    )
  }

  return <LoginWithPasswordForm header={header} />
}

function LoggedInNavigator() {
  const sdk = useSDK()

  const auth = useAuthClient()
  const authState = useAuthState()

  const membershipsReponse = usePromise(
    () => sdk.send({ type: 'organizations/getMyOrganizationMemberships' }),
    [sdk]
  )

  return match(membershipsReponse)
    .with(
      { status: 'fulfilled', value: { memberships: P.when((arr) => arr.length == 1) } },
      (resp) => <RedirectToOrganization membership={resp.value.memberships[0]} />
    )
    .with(
      { status: 'fulfilled', value: { memberships: P.when((arr) => arr.length == 1) } },
      (resp) => <RedirectToOrganization membership={resp.value.memberships[0]} />
    )
    .with(
      { status: 'fulfilled', value: { memberships: P.when((arr) => arr.length > 1) } },
      (resp) => <OrganizationSelector memberships={resp.value.memberships} />
    )
    .with({ status: 'rejected' }, () => <ErrorMessage message="Failed to load organizations" />)
    .with({ status: 'pending' }, () => <></>)
    .otherwise(() => (
      <div className="flex flex-col gap-2">
        <h2 className="mb-4 text-center">
          You are not in any organizations. You must create one or be invited to one
        </h2>
        <p className="text-center text-sm">Logged in as {authState.user?.email}</p>
        <Button variant="primary" onClick={() => auth.logout()}>
          Log out
        </Button>
      </div>
    ))
}

function OrganizationSelector({ memberships }: { memberships: OrganizationMembership[] }) {
  const setRoute = useSetRoute()

  const options: Option[] = memberships.map((m) => {
    return { key: m.organization.slug, label: m.organization.name }
  })

  return (
    <div className="flex flex-col gap-10">
      <h2>Please select an organization</h2>

      <RadioSelect
        options={options}
        value={undefined}
        onChange={(key) => setRoute({ name: 'folder_list', organizationSlug: key })}
      />
    </div>
  )
}

type ResetPasswordState =
  | { status: 'idle' }
  | { status: 'running' }
  | { status: 'succeeded' }
  | { status: 'failed'; error: AuthError }

function ResetPasswordForm({
  header,
  token,
  onClickLogin
}: {
  header: ReactNode
  token: string
  onClickLogin: () => void
}) {
  const auth = useAuthClient()
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [state, setState] = useState<ResetPasswordState>({ status: 'idle' })

  async function onSubmit() {
    const result = await auth.resetPassword(token, password, passwordConfirmation)
    if (result.ok) {
      setState({ status: 'succeeded' })
    } else {
      setState({ status: 'failed', error: result.error })
    }
  }

  return (
    <div className="w-max-400 flex w-full flex-col gap-4" onSubmit={onSubmit}>
      {header}

      <div className="flex flex-col items-stretch gap-5">
        {match(state)
          .with({ status: 'succeeded' }, () => (
            <>
              <p className="text-center text-base">Password set successfully. Please login.</p>

              <Button variant="primary" onClick={onClickLogin}>
                Continue to Login
              </Button>
            </>
          ))
          .with({ status: P.union('idle', 'running', 'failed') }, (state) => (
            <>
              <div className="text-base">Enter your new password below.</div>
              <TextInput
                placeholder="Password"
                type="password"
                autoComplete="off"
                value={password}
                onChange={setPassword}
              />
              <TextInput
                placeholder="Confirm password"
                type="password"
                autoComplete="off"
                value={passwordConfirmation}
                onChange={setPasswordConfirmation}
              />
              <Button variant="primary" onClick={() => onSubmit()}>
                {state.status == 'running' ? 'Setting password...' : 'Set password'}
              </Button>
              {state.status == 'failed' ? (
                <p className="error-message text-center">{formatAuthError(state.error)}</p>
              ) : null}
            </>
          ))
          .exhaustive()}
      </div>
    </div>
  )
}

function LoginWithPasswordForm({ header }: { header: ReactNode }) {
  const { send } = useProcess(authModule)
  const { status, error } = useAuthState()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [mode, setMode] = useState<'login' | 'resetPassword'>('login')

  async function onSubmit(e: React.SyntheticEvent) {
    e.preventDefault()

    await send(loginWithEmailPassword({ email, password }))
  }

  if (mode == 'resetPassword') {
    return <SendPasswordResetEmailForm header={header} />
  }

  return (
    <form className="w-max-400 flex w-full flex-col gap-10" onSubmit={onSubmit}>
      {header}

      <div className="flex flex-col items-stretch gap-5">
        <TextInput
          type="text"
          placeholder="Email"
          value={email}
          autoFocus={true}
          onChange={setEmail}
        />

        <TextInput
          placeholder="Password"
          type="password"
          autoComplete="off"
          value={password}
          onChange={setPassword}
        />

        {status == 'loggingIn' ? (
          <Button disabled={true} variant="primary">
            Logging In
          </Button>
        ) : (
          <Button type="submit" variant="primary">
            Log In
          </Button>
        )}

        {error ? <p className="error-message text-center">{formatAuthError(error)}</p> : null}
      </div>

      <div className="text-center">
        {"Don't have an account?"}{' '}
        <Link className="text-blue-500" href={{ name: 'sign_up' }}>
          Sign up
        </Link>
      </div>

      <div className="text-center text-sm">
        Forgot your password?{' '}
        <a className="cursor-pointer text-blue-500" onClick={() => setMode('resetPassword')}>
          Click here
        </a>{' '}
        to reset it.
      </div>
    </form>
  )
}

function SendPasswordResetEmailForm({ header }: { header: ReactNode }) {
  const sdk = useSDK()

  const [email, setEmail] = useState('')
  const [status, setStatus] = useState<'idle' | 'sending' | 'sent'>('idle')

  function onClick() {
    setStatus('sending')
    sdk.send({
      type: 'accounts/sendPasswordResetEmail',
      email,
      continueUrl: envVar('NEXT_PUBLIC_APP_URL')!
    })
    setStatus('sent')
  }

  return (
    <form className="w-max-400 flex w-full flex-col gap-10">
      {header}

      <div className="flex flex-col items-stretch gap-5">
        <TextInput
          autoFocus={true}
          type="text"
          placeholder="Email"
          value={email}
          onChange={setEmail}
        />

        {match(status)
          .with('idle', () => (
            <Button variant="primary" onClick={onClick}>
              Reset password
            </Button>
          ))
          .with('sending', () => (
            <Button disabled={true} variant="primary">
              Sending password reset email
            </Button>
          ))
          .with('sent', () => (
            <div className="text-base">
              Please check your email at {email} for the password reset link.
            </div>
          ))
          .exhaustive()}
      </div>
    </form>
  )
}

function RedirectToOrganization({ membership }: { membership: OrganizationMembership }) {
  const setRoute = useSetRoute()

  useEffect(() => {
    setRoute({
      name: 'folder_list',
      organizationSlug: membership.organization.slug
    })
  }, [membership.organization.slug])

  return <></>
}

function StanfordTaxBrandLogo() {
  return <BrandLogo className="text-3xl" href={process.env.NEXT_PUBLIC_HOMEPAGE_URL} />
}

// New Header Components
export function OrganizationHeader({ organization }: { organization: Organization | undefined }) {
  if (!organization) return <StanfordTaxHeader />

  return (
    <div className="flex flex-col items-center gap-3">
      <StanfordTaxBrandLogo />
      {organization.logo ? <OrganizationLogo src={organization.logo} size={80} /> : null}
      <h1 className="text-3xl">{organization.name}</h1>
    </div>
  )
}

export function StanfordTaxHeader() {
  return (
    <div className="flex flex-col items-center gap-3">
      <StanfordTaxBrandLogo />
    </div>
  )
}
